/**
	LOGIN
========================================================================================== 
**/

$(document).ready(function(){

	/* Invio login
	------------------------------------------------------------------------------------------ */
	$('#login-form').on('submit', function(evt){
		evt.preventDefault();

		var $_form = $(this);

		LoaderManager.start();

		$.ajax({
			url: $_form.attr("action")
			,data: $_form.serialize()
			,type: 'POST'
			,dataType: 'json'
			,error: function() {
				UserDialog.error();
			}
		}).done(function(result){
			// Verifico il risultato
			if(parseInt(result.success) === 1){ // OK

				//UserDialog.success(result.msg);

				// Redirect
				if(typeof result.redirect !== 'undefined' && result.redirect){
					setTimeout(function(){
						window.location = result.redirect;
					}, 1000);
				}
			}else{ // Non OK
				LoaderManager.stop();
				UserDialog.error(result.msg);
			}
		});
	});
});
