/* Gestione lista voci
========================================================================================== */
;(function($, window, document, undefined){

    $.fn.SetListCMS = function(options) {
        options = $.extend( {}, $.fn.SetListCMS.options, options);

        
        const thisList = $(this);
        
        /* aggiornamento pubblicazione
        ------------------------------------------------------------------------------------------ */
        thisList.on("click", '.list_item_public_status', function(evt){
            evt.preventDefault();
            evt.stopImmediatePropagation();

            let thisLink = $(this);
            let thisControl = $(this).parent(".field-control");

            // Richiesta
            $.ajax({
                url: thisLink.attr("href"),
                dataType: 'json',
                method: 'PATCH',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                success: function(result){
                    
                    thisLink.fadeOut(400, function(){
                        thisControl.attr('data-current-status', result.public);
                        thisLink.fadeIn(400);   
                    });
                },
                // errore di parsing o errore nel codice di risposta
                error: function(a,b) {
                    UserDialog.error(a.responseText || 'Si è verificato un errore.');
                }
            });
        });
        
        
        /* aggiornamento "switch" Y/N
        ------------------------------------------------------------------------------------------ */
        thisList.on("click", '.list_item_update_switch', function(evt){
                evt.preventDefault();
                evt.stopImmediatePropagation();
                let thisLink = $(this);
                let thisControl = thisLink.parent(".field-control");
                let group = thisControl.attr("data-group");
                
                let currentStatus = thisControl.attr("data-current-status");

                var newStatus = currentStatus == 'Y' ? 'N' : 'Y';
                
                // se data-single="true" le altre voci vanno disabilitate (deve essere concorde con le operazioni lato server che operano lo switch)
                let multipleAllow = thisControl.attr("data-single") == 'true' ? false : true;
                
                thisLink.fadeOut(400, function(){

                    // Richiesta
                    $.ajax({
                        url: thisLink.attr("href"),
                        dataType: 'json',
                        method: 'PATCH',
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        success: function(result){
                            
                            // disabilito le altre voci dello stesso gruppo se la proprietà è esclusiva
                            if ( newStatus == 'Y' && multipleAllow == false) {
                                thisList.find('.field-control-switch[data-group="'+group+'"]').attr("data-current-status", 'N');
                                thisList.find('.field-control-switch[data-group="'+group+'"] .order').text('N');
                            }

                            // inverto stato se chiamata è andata a buon fine
                            thisControl.attr("data-current-status", newStatus);
                            thisControl.find(".order").text(newStatus); // elementi nascosti che fungono da ordinamento
                            //console.log(thisList.filter("table").DataTable());
                            thisList.filter("table").DataTable().draw(true);
                            
                            thisLink.fadeIn(400);



                        },
                        error: function() {
                            UserDialog.error('Si è verificato un errore.');
                        }
                    });
                });
        });


        /* Cancellazione voce in lista
        ------------------------------------------------------------------------------------------ */
        thisList.on("click", '.list_item_delete', function(evt){
                evt.preventDefault();
                evt.stopImmediatePropagation();
                let $_this = $(this);

                UserDialog.confirm("You are going to delete this record. Are you sure?", "Confirm", function() {
                    
                    $.ajax({
                        url : $_this.attr("href")
                        ,method: 'DELETE'
                        ,dataType: 'json'
                        ,headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        success: function(result) {
                            
                            if (result.error) {
                                return UserDialog.error(result.error);
                            }

                            // in data ho la lista degli id rimossi
                            data = result.data;
                            $.each(data, function(el) {
                                thisList.find('.row_'+this).fadeOut(500);
                            });

                            if (thisList.filter("table").length) {
                                thisList.DataTable().draw();
                            }

                            if (thisList.find("tbody tr").length == 0) {window.location.reload();}

                        }
                        ,error: function() {
                            UserDialog.error();
                        }

                    });
                });
        });
        
        /* Copia voce in lista
        ------------------------------------------------------------------------------------------ */
        thisList.on("click", '.list_item_copy', function(evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                let $_this = $(this);

                UserDialog.confirm("Copiare la voce?", "Conferma", function () {

                    $.ajax({
                        url : $_this.attr("href")
                        ,method: 'POST'
                        ,dataType: 'json'
                        ,headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                        ,success: function(result) {
                            
                            if (result.error) {
                                return UserDialog.error(result.error);
                            }

                            UserDialog.success(result.msg);

                            // possibile migliorare, per ora va bene così
                            setTimeout(function(){window.location.reload();}, 1000);

                        }
                        ,error: function() {
                            UserDialog.error();
                        }
                    });
                });
        });


        /* propagazione click modifica su riga
        ------------------------------------------------------------------------------------------ */
        /*thisList.on("click", "tbody tr", function(e) {
            
            var location = $(this).find("td.row-tools a.list_item_update").attr("href");
            
            LoaderManager.start();
            
            window.location = location;
        });*/

        
        /**
         * Apre popup di edit traduzione
         */
        if($("#translation-modal").length) {

            var $m = UIkit.modal($("#translation-modal"));

            thisList.on("dblclick", "tr td.translation [data-autosave]", function(e) {
                
                var source = $(this);
    
                $("#translation-modal textarea").val(source.val());
    
                $m.show();
    
                // al click su "salva", copio il contenuto nella cella di origine e 
                // lacnio evento change per far partire il salvataggio
                $("#translation-modal").off("click").on("click", '.save', function() {
                    source.val($("#translation-modal textarea").val());
                    source.trigger("change");
                    $m.hide();
                });
    
    
            });
        }    


        /* autosave sui campi (scrivere eventuali funzioni custom per estendere funzionalità)
        ------------------------------------------------------------------------------------------ */
        thisList.on("click", "tr td [data-autosave]", function(e) {
            e.stopImmediatePropagation();
        });
        thisList.on("change", "tr td [data-autosave]", function(e) {
            var $this = $(this);

            var timeout = null;
            
     
                    if (timeout !== null) {clearTimeout(timeout);}
 
                    timeout = setTimeout(function() {
                        
                        var action = $this.attr("data-action");

                        $.ajax({
                            url : action
                            ,data : {
                                value : $this.val()
                            }
                            ,dataType: 'json'
                            ,method: 'PATCH'
                            ,headers: {
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            }
                            ,beforeSend: function() {
                                $('<div data-uk-spinner class="spinner"></div>').insertAfter($this);

                            }
                            ,success: function(response) {

                                $this.next(".spinner").remove();

                                if (response.error) {
                                    $this.addClass("uk-form-error");
                                    return UserDialog.error(response.error);
                                }

                                // aggiorno con valore di risposta
                                if (response.value) {
                                    $this.attr("value", response.value);
                                    $this.val(response.value);
                                    // aggiorno attributo ad uso di datatable per l'ordinamento
                                    $this.parents("td").attr("data-order", response.value);
                                    $this.parents("td").data("order", response.value);
                                    thisList.DataTable().draw();
                                }

                                $this.addClass("uk-form-success");

                            }
                            ,error: function() {
                                
                                $this.addClass("uk-form-error");

                                $this.next(".spinner").remove();
                                
                                UserDialog.error();
                            }
                        }).done(function() {
                            setTimeout(function() {
                                $this.removeClass("uk-form-error uk-form-success");
                            }, 3000);
                        });

                    }, 500); // timeout

        });
               

        /* gestione lista con datatable
        ------------------------------------------------------------------------------------------ */
        
        /**
         * Preparo colonne
         */
        var columns = [];
        thisList.find('th').each(function(i) {

            // uso ".attr()"" per verificare se l'attributo è impostato, ".data()"" per recuperarlo
            // in modo da averlo come booleano
            var c = {
                name : $(this).attr("data-name")
                ,className : $(this).attr("data-name")
                ,searchable : $(this).attr("data-searchable") ? $(this).data("searchable") : true
                ,visible : $(this).attr("data-visible") ? $(this).data("visible") : true
                ,orderable : $(this).attr("data-orderable")  ? $(this).data("orderable") : true
            };

            columns.push(c);

        });


        /**
         * avvio plugin DataTable se la lista è una tabella
         */
        var t = thisList.filter("table");
        
        // id per differenziare istanze nel salvataggio dello stato (v. stateLoadCallback)
        if (!t.attr("id")) {
            t.attr("id", 'table_'+$("body").attr("id"));
        }

        // aggiungere a tabella atrbiuto 'data-datatable-enable="false" per disabiltiare datatable
        let enable = $(t).data('datatable-enable') ? $(t).data('datatable-enable') : true;

        if (t.length && enable == true) {
            t.DataTable($.extend({}, GLOBALS.dataTableDefaultOptions, {
                
                columns : columns
                ,dom: 'liptp'  // length-info-pager-table
                ,order : []
                //,bPaginate: true
                ,initComplete: function (settings) {

                    var dt = this;
                    var tr = thisList.find("tr");

                    /**
                     * riporto classi di formattazione (applicate su th)
                     * alle celle corrispondenti nelle righe
                     */
                    thisList.find("th").each(function(i) {
                        if ($(this).hasClass("center")) {
                            tr.each(function() {
                                $(this).find("td:eq("+i+")").addClass("center");
                            });
                        }
                    });

                    /**
                     * riporto link di edit sulle celle "valore"
                     */
                    thisList.find("td:not(.dataTables_empty).value").each(function() {

                        var edit = $(this).parent("tr").find(".list_item_update").attr("href");

                        var content = $(this).html();

                        // non riporto link se ne esiste già uno (es. per eventuali celle custom)
                        if($(this).find('a').length) {
                            return;
                        }


                        $(this).html('<a href="'+edit+'">'+content+'</a>');
                    });

                    /**
                     * Propago click di edit su tutta la riga
                     */
                   /*  thisList.on("click", "td:not(.dataTables_empty):not(.translation)", function(e) {
                        var edit = $(this).parent("tr").find(".list_item_update").attr("href");
                        window.location = edit;
                    }); */


                    /**
                     * ricerca in datatable su campo creato manualmente all'esterno
                     */
                    var $search = $('#page-content-filterbar').find('input[type="search"]');
                    $search.on('keyup', function(){
                        let q = $(this).val();
                        t.DataTable().search(q).draw();
                        //localStorage.setItem( 'DataTables_search_' + );
                    });     


                    /**
                     * lancio evento ad uso di altri script
                     */
                    thisList.trigger("datatable_inited");


                    /**
                     * recupero stato e precompilo i campi
                     */
                    //console.log(thisList.DataTable().settings.sInstance);
                    //return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )
                
                }
                ,stateSave: true
                ,stateSaveCallback: function(settings,data) {
                    localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) );
                }
            ,stateLoadCallback: function(settings) {
                    var s = JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )

                    // ripristino form di ricerca
                    if (s && s.search.search) {
                        var $search = $('#page-content-filterbar').find('input[type="search"]');
                        $search.val(s.search.search).addClass("warning");
                    }

                    return s;
                }
            }));
        

            /**
             * ordinamento sulla base del valore degli input autosave per le celle che li contengono
             */
            /*thisList.find("tr").each(function() {
                $(this).find("td").each(function() {
                    var $cell = $(this);
                    var $input = $cell.children('[data-autosave]');
                    if ($input.length) {
                        $cell.attr("data-order", $input.val());
                    }
                });
            });*/

            thisList.DataTable().on("draw", function() {
                thisList.find("td").each(function() {
                    var $cell = $(this);
                    var $input = $cell.children('[data-autosave]');
                    if ($input.length) {
                        $cell.attr("data-order", $input.val());
                        $cell.data("order", $input.val());
                    }
                });
            });
        } // istanzio datatable se ho lista formato tabella

    
    }
    // Opzioni di default
    $.fn.SetListCMS.options = {
    }

})(jQuery, window, document);


/**
 * Aggiorna url all'evento di filtro
 */
function list_changeLocationOnFilterChange(eventname) {
    $(document).on(eventname, function(event){

        var uri = new Uri(window.location);
        uri.deleteQueryParam(event.filterName).addQueryParam(event.filterName, event.filterValue);

        history.pushState(null, null, uri.toString());

    });
}
