/**
 * Dialog per comunicazioni a utente,
 * basato su Jquery Dialog
 */
UserDialog = (function UserDialog() {

    UserDialog.inited = false;

    UserDialog.init = function() {

        if (UserDialog.inited) {return UserDialog;}
        
        UserDialog.inited = true;

        return UserDialog;
    };

    /**
     * messaggio di alert
     */
    UserDialog.alert = function(content) {

        UserDialog.init();
    
        UIkit.modal.alert(content);
    
    }


    /**
     * richiesta di conferma
     */
    UserDialog.confirm = function (content, title, successCallback, rejectCallback) {

        UserDialog.init();  
        
        var str = '<div>';
        str += title ? '<h2 class="uk-modal-title">'+title+'</h2>' : '';
        str += content;
        str += '<button class="uk-modal-close-default" type="button" uk-close></button>';
        str += '</div>';

        UIkit.modal.confirm(str, {
            labels: {
                ok: 'Ok',
                cancel: 'Cancel'
            }
        }).then(function() {
            if (typeof(successCallback) == 'function') {successCallback()};
        }, function () {
            if (typeof(rejectCallback) == 'function') {rejectCallback()};
        });

        return UserDialog;
    }

    /**
     * Messaggio di errore
     */
    UserDialog.error = function (content,close_timeout) {
        
        var content = content || 'Si è verificato un errore.';

        UserDialog.init();

        var options = {
            status : 'danger'
            ,timeout : close_timeout || 5000
        };

        UIkit.notification('<div class="userdialog-content userdialog-notification userdialog-error">'+content+'</div>', options);
        
        return UserDialog;

    }
    

    /**
     * Messaggio di conferma
     */
    UserDialog.success = function (content,close_timeout) {
        
        UserDialog.init();

        var options = {
            status : 'success'
            ,timeout : close_timeout || 5000
        };

        UIkit.notification(content, options);

        return UserDialog;
    }


    /**
     * Popup generico
     */
    UserDialog.popup = function (content, title, optionsObject) {

        UserDialog.init();
            
        var str = '<div class="uk-modal-body">';
        str += title ? '<h2 class="uk-modal-title">'+title+'</h2>' : '';
        str += content;
        str += '<button class="uk-modal-close-default" type="button" uk-close></button>';
        str += '</div>';

        UIkit.modal.dialog(str);
        
        return UserDialog;

    }


    /**
     * chiusura
     */
    UserDialog.close = function() {

    }

    
    jQuery(document).ready(function($) {
        UserDialog.init();
    });
 
    return UserDialog;

})();

/** 
 * LOADER MANAGER
 * Consente di attaccare/rimuovere degli eventi ad una coda, quando la coda non contiene più
 * elementi viene eseguito il callback definito (es. far sparire un loader).
 * (per gestire successione delle operazioni iniziali attraverso script multipli)
 * Consente di far partire un evento di loading.
 *
 * MOdificare le funzioni onStart e onStop per personalizzarne il comportamento,
 * rispettivamente, per l'inizio e la fine del caricamento. 
 *
 * Richiede jquery 2+ se si vuole usare funzionalità di progressBar.
 */
(LoaderManager = function LoaderManager() {

    var currentEvents = [];
    var isLoading = true;

    LoaderManager.addEvent = function(event_name) {

        //console.log(isLoading);
        if (isLoading == false) {LoaderManager.start();}
        
        // controlla che l'evento non sia già presente
        if (event_name && currentEvents.indexOf(event_name) == -1) {currentEvents.push(event_name);}
        
        //console.log("Event added: " + event_name);

        return LoaderManager;

    };

    LoaderManager.eventComplete = function(event_name) {
        
        //console.log("Event complete: " + event_name);
        
        // rimuovo elemento da array     
        if (event_name && currentEvents.indexOf(event_name) >= 0) {currentEvents.splice( currentEvents.indexOf(event_name), 1 );}

        //console.log("Events queue: ", currentEvents);

        if (!currentEvents.length) {
            
            //console.log("Events completed!!");
            isLoading = false;

            LoaderManager.stop();

        }
        return LoaderManager;

    };

    LoaderManager.start = function () {
        return LoaderManager.onStart();
    };
    
    LoaderManager.stop = function () {

        LoaderManager.messageClear();

        LoaderManager.progressBarClear(); // se aggiunta precedentemente

        return LoaderManager.onStop();

    };

   
    LoaderManager.progressBar = function() {

        LoaderManager.progressBarClear(); // se già aggiunta precedentemente
        
        $('<div class="loadermanager-progress"><div class="loadermanager-progressbar"></div></div>').appendTo("body");

        return LoaderManager;
    };

    LoaderManager.progressBarUpdate = function(percentComplete) {

        $("body").find(".loadermanager-progressbar").width(percentComplete+'%');

        return LoaderManager;  
    
    };

    LoaderManager.progressBarClear = function() {
        $("body").find(".loadermanager-progress").remove(); 
        return LoaderManager;
    };


    /**
     * Messaggio
     */
    LoaderManager.message = function(msg) {
        
        LoaderManager.messageClear();
        
        $('<div class="loadermanager-dialog">'+msg+'</div>').prependTo("html");

        return LoaderManager;
    };


    /**
     * Rimozione messaggio
     */
    LoaderManager.messageClear = function() {

        $("html").find(".loadermanager-dialog").remove();

        return LoaderManager;
    }


    /**
     * eseguita a inizio caricamento
     * Va personalizzata a piacere.
     */
    LoaderManager.onStart = function () {
        $("html").removeClass("loadermanager-page_loaded");

        return LoaderManager;
    };
    
    /**
     * eseguita a fine caricamento
     * Va personalizzata a piacere.
     */
    LoaderManager.onStop = function () {
        $("html").addClass("loadermanager-page_loaded");
        $(document).trigger("loadermanager-page_loaded");
    };


    return LoaderManager;
})();

/**
 * Funzionalità comuni per gli script delle funzioni
 */


/**
 * Lancia evento custom al variare dei filtri
 */
listenForFilterChange = function($obj, eventname) {
    $obj.each(function() {
        
        
        $(this).on("change", function() {
            
            var  name = $(this).attr("name");
            var  value = $(this).val().length ? $(this).val() : null;
            
            // lancio evento custom ad uso di altre funzioni
            $(document).trigger({
                type : eventname
                ,filterName : name
                ,filterValue : value
            });   
            

            var uri = new Uri(window.location);
            uri.deleteQueryParam(name).addQueryParam(name, value);
            window.location = uri.toString();
    
        });
    });
}



/**
 * Logout
 */
listenForLogout = function($item) {

    $item.on('click', function(evt){

        evt.preventDefault();
        var href = $(this).attr("href");

        UserDialog.confirm('Are you sure?', 'Logout', function () {

            LoaderManager.start();

            $.ajax({
                type: 'GET'
                ,dataType: 'json'
                ,url : href
                ,success: function(response) {
                    if (response.status == false) {
                        LoaderManager.stop();
                        return UserDialog.error(response.msg);
                    }

                    //UserDialog.success(response.msg);

                    setTimeout(function() {
                        window.location = GLOBALS.baseUrl+'/';
                    }, 500);
                }
                ,error: function() {
                    UserDialog.error(response.msg);
                }
            });
        });
    });
}



/**
 * Apre una funzione in un iframe.
 * Aggiunge automaticamente parametro iframe all'url.
 * Alla chiusura del popup contenente l'iframe, lancia un evento
 * ad uso esterno.
 */
iframeFunction = function($obj) {
    var href = $obj.attr("href");
    href = new Uri(href);
    href.addQueryParam("iframe", 1);

    var $el = $('<div class="uk-modal function-iframe-modal"><button class="uk-modal-close-default" type="button" uk-close></button><iframe class="function-iframe" src="'+href.toString()+'"></iframe></div>');
    var m = UIkit.modal($el);
    m.show();

    // propago automaticamente parametro "iframe"
    /*$el.find("iframe").on("load", function() {
        $el.find("iframe").contents().find("#page-content-breadcrumbs a,#page-content-titlebar a,.items-list a").each(function() {
            var href = new Uri($(this).attr("href"));
            href.addQueryParam("iframe", 1);
            $(this).attr("href", href);
        });
    });*/

    // lancio evento alla chiusura per eseguire operazioni ulteriori
    UIkit.util.on('.function-iframe-modal', 'hide', function(e) {
        $(document).trigger("iframe-function-close", e);
    });

}


/**
 * Scroll se hash nell'url
 */
scrollAnchor = function() {
    var uri = new Uri(window.location);
    var anchor = uri.anchor();
    if (anchor && $('#'+anchor).length) {
        $(document).on("loadermanager-page_loaded", function() {
            $("body,html").animate({
                scrollTop: $('#'+anchor).offset().top
            }, 200);            
        });
    }
}


/**
 * Interventi su UI
 * */
ui = function()
{   

}



/**
 * Copy text to clipboard and execute a callback
 */
copyToClipboard = function(text, successCallback, errorCallback)
{
    try {

        // navigato.clipboard is undefined on localhost!
        navigator.clipboard.writeText(text);

        if(successCallback) {
            successCallback();
        }

    } catch (err) {
        console.error(err);
        
        if(errorCallback) {
            errorCallback();
        }

    }

}