/**
 * Bundled with
 * packages/atrioteam/marvin/src/resources/js/lib/lib.js,
 * packages/atrioteam/marvin/src/resources/js/lib/login.js,
 * packages/atrioteam/marvin/src/resources/js/lib/list.js,
 * packages/atrioteam/marvin/src/resources/js/lib/input.js,
 */

$(function() {


    /**
     * 
     * Funzionalità globali in tutte le sezioni
     * 
     */


    /**
     * Avvio caricamento pagina
     */
    LoaderManager.addEvent("main_loading");

    /**
     * Opzioni di default per datatable
     */
    GLOBALS.dataTableDefaultOptions = {
        //,'deferRender': true
        'columnDefs': [
            {'targets': 'no-sort','orderable': false}
        ]
        ,'info': false
        ,'paging': false
        ,'bLengthChange': false
        ,'order': [] // Disabilito l'ordinamento iniziale
        ,'language': {'url': GLOBALS.baseUrl+'/js/datatables-it.json'}
    };


    /**
     * Init
     */
    $(function() {

        // scroll (disabilitato perchè interferisce con manipolazione url)
        //scrollAnchor();

        // fine caricamento
        LoaderManager.eventComplete("main_loading");

        // link base per apertura funzioni a popup
        $(document).on("click", ".function-iframe-link", function(e) {
            e.preventDefault();
            iframeFunction($(this));
        });


        /**
         * Logout
         */
        listenForLogout($("#cms_logout"));


        /**
         * Cambio di url (hash)
         * Evento browser, cosicchè possa essere lanciato
         * sia da componenti livewire che da altri
         */
        window.addEventListener('hashLocationChanged', (e) => {
            window.location.hash = e.detail;
        });

        
        /**
         * Cambio di url
         * Evento browser, cosicchè possa essere lanciato
         * sia da componenti livewire che da altri
         */
        window.addEventListener('locationChanged', (e) => {
            window.location = e.detail;
        });


        /** 
         * Evento di cambiamento url lanciato dai componenti.
         * Usato per logging / tracking
         */
        Livewire.on('trackUrl', (url, title) => {
            window.location.hash = url;

            if ("gtag" in window) {
                gtag('event', 'page_view', {
                    page_path: url,
                    page_title: title,
                    send_to: UA_MEASUREMENT_ID
                });
                    
            }
        //history.pushState(null, null, url);
        });

        
        /**
         * Tracking eventi.
         * Rimane in ascolto delle istruzioni di tracking dal componente.
         */
        Livewire.on('trackEvent', (category, action, name, value) => {
            if ("gtag" in window) {
                gtag('event', action, {
                    event_category: category,
                    event_label: name,
                    event_value : value,
                });
            }
        });


        /**
         * Errore lanciato da componente backend
         */
        Livewire.on("ui:error", function(response) {
            UserDialog.error(response.message);
        });

        
    });

   
});
