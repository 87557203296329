/**
 * 
 * Funzionalità globali per la pagine di input
 * Se ci sono eccezioni nel progetto, utilizzare custom/input.js per modificare
 * le funzionalità (nei limiti del possibile)
 * 
 */

/**
 * Funzione di init editor
 */
inputCreateEditors = function(selector) {


    // per permettere attributi di allineamento sull'immagine.
    // attributi aggiunti dal modulo imageResize
    // v. https://github.com/koan00/quill-image-resize-module/commit/95ba869e4045d267062c3c01091c121141f7004d
    // BEGIN allow image alignment styles
    const ImageFormatAttributesList = [
        'alt',
        'height',
        'width',
        'style'
    ];

    var BaseImageFormat = window.Quill.import('formats/image');
    class ImageFormat extends BaseImageFormat {
        static formats(domNode) {
        return ImageFormatAttributesList.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
            formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
        }
        format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
            this.domNode.setAttribute(name, value);
            } else {
            this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
        }
    }

    window.Quill.register(ImageFormat, true);
    //END allow image alignment styles

    // SmartBreak to allow br on Shift + Enter
    const Delta = Quill.import("delta");
    const Break = Quill.import("blots/break");
    const Embed = Quill.import("blots/embed");
    
    const lineBreakMatcher = () => {
      let newDelta = new Delta();
      newDelta.insert({ break: "" });
      return newDelta;
    };
    
    class SmartBreak extends Break {
      length() {
        return 1;
      }
      value() {
        return "\n";
      }
    
      insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref);
      }
    }
    
    SmartBreak.blotName = "break";
    SmartBreak.tagName = "BR";
    Quill.register(SmartBreak);
    // END SmartBreak 

    const quillEditors = []; // Array container of editors

	$(selector).each(function() {
		let thisID = $(this).attr('id');

        let thisContent = $(this).is('textarea') ? $(this).val() : $(this).html();

        // remove attrbiutes name from this element (if it's a textarea, to make this retrcompatible with ckeditor's textareas)
        $(this).removeAttr('name');
        
		// Add the sibling textarea, hidden, to the DOM
		$(this).after('<textarea class="uk-hidden '+thisID+'" name="'+thisID+'" data-dynamic="">'+thisContent+'</textarea>');
        
        let newEl = $('<div id="'+thisID+'">' + thisContent + '</div>');
        if( $(this).is('textarea')) {
            // definisco inizialmente un altezza definita sull'attributo rows.
            // Poi eventualmente verrà sovrascritta da style (v. sotto)
            if($(this).attr('rows')) {
                newEl.css('height', $(this).attr('rows') * 2 + 'em');
            }
        }
        $.each(this.attributes, function() {
            newEl.attr(this.name, this.value);
        });

        $(this).replaceWith(newEl);
    
        LoaderManager.addEvent('quill_load_' + thisID);

		quillEditors[thisID] = new Quill('#'+thisID, {
			theme: 'snow',
			modules: {
				toolbar: QuillToolbarOptions, // Based on /admin/js/quill_config.js
                imageResize: {
                    modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
                },
                clipboard: {
                    matchers: [["BR", lineBreakMatcher]],
                    matchVisual: false
                },
                keyboard: {
                    bindings: {
                      linebreak: {
                        key: 13,
                        shiftKey: true,
                        handler: function(range) {
                          const currentLeaf = this.quill.getLeaf(range.index)[0];
                          const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
                          this.quill.insertEmbed(range.index, "break", true, "user");
                          // Insert a second break if:
                          // At the end of the editor, OR next leaf has a different parent (<p>)
                          if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                            this.quill.insertEmbed(range.index, "break", true, "user");
                          }
                          // Now that we've inserted a line break, move the cursor forward
                          this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                        }
                      }
                    }
                }
			}
		});

		// Update value of sibling textarea, when editor changes.
		// Must to have the same name (on class) of the ID editor
		quillEditors[thisID].on('text-change', function() {
			$('textarea.'+thisID).val(quillEditors[thisID].root.innerHTML);
		});

		LoaderManager.eventComplete('quill_load_' + thisID);
	});
}


/**
 * Colorpicker
 */
inputCreateColorPicker = function(selector) {

    $(selector).each(function(i) {

        var $_input = $(this);
        $_input.attr("type", "hidden");
        var $_colorpicker = $('<div class="colorpicker"></div>').appendTo($(this).parent());

        var color = $_input.val() ? $_input.val() : '#ffffff'

        $_colorpicker.colpick({
            flat: true,
            layout: 'hex',
            submit: 0,
            color: color,
            onChange: function(hsb, hex, rgb) {
                $_input.val('#' + hex);
            }


        });

    });
}


/**
 * Campi datepicker
 */
inputCreateDatepicker = function(selector) {
    if(!$(selector).length) return;
    $(selector).each(function(i, el) {
        $(el).width(100);

        // TODO: recupero configurazione aggiuntiva da html
        var options = $.extend({},
            //            $(el).data(),
            {
                dateFormat: 'dd/mm/yy',
                lang: 'it'
            }
        );
        $(el).datepicker(options);
    });
}


/**
 * Campi datetimepicker
 */
inputCreateDateTimepicker = function(selector) {

    $.timepicker.regional['it'] = {
        timeOnlyTitle: 'Impostate ora',
        timeText: 'Ore:Min:Sec',
        hourText: 'Ore',
        minuteText: 'Minuti',
        secondText: 'Secondi',
        millisecText: 'Millisecondi',
        timezoneText: 'Fuso orario',
        currentText: 'Adesso',
        closeText: 'Chiudi',
        timeFormat: 'HH:mm:ss',
        amNames: ['AM', 'A'],
        pmNames: ['PM', 'P'],
        isRTL: false
    };
    $.timepicker.setDefaults($.timepicker.regional['it']);

    $(selector).not('[readonly]').each(function(i) {
        $(this).width('10em');

        $(this).datetimepicker();
    });
}


/**
 * crea barra di navigazione fixed
 */
inputNavBar = function() {

    var id = $(".form_item").attr("id")
    if (!id) {
        var id = 'input_' + (new Date().getTime());
        $(".form_item").attr("id", id);
    }

    // clono pulsante lista
    var $list = $("#page-content-titlebar a.list");
    
    if($list.length) {
        $list = $list.clone(true).addClass("uk-button uk-button-primary list").text("Torna alla lista");
    } else {
        // creo comunque un elemento per mantenere justify ed evitare che si spoti il tasto salva
        $list = $('<span />');
    }

    // sposto pulsante di salvataggio
    var $savebar = $('.form_item .input-savebar');
    var $save = $savebar.find('[type="submit"]').attr("form", id);

    if ($list.length || $save.length) {
        $navbar = $('<div class="input-navbar"/>');
        $navbar.append($list);
        $navbar.append($savebar);

        $navbar.appendTo('#main-wrapper');
    }

}


/**
 * UI
 */
inputUi = function() {

    /**
     * form seo
     */
    UIkit.toggle(".seo_data_title", {
        target: '#form-seo'
    });
    $("#form-seo").on("shown", function() {
        $(".seo_data_title .uk-icon").attr("data-uk-icon", "chevron-up");
    }).on("hidden", function() {
        $(".seo_data_title .uk-icon").attr("data-uk-icon", "chevron-down");
    });

}


/**
 * Gestione salvataggio form
 */
;
(function($, window, document, undefined) {

    $.fn.SaveForm = function(options) {
        options = $.extend({}, $.fn.SaveForm.options, options);

        const thisForm = $(this);

        thisForm.options = options;

        var uploaderQueue = new Array;


        /**
            Azioni form
        ========================================================================================== 
        **/
        // Invio
        thisForm.on('submit', function(evt) {

            evt.preventDefault();

            LoaderManager.start();

            formSerialize();

        });

        /**
         * disabilita validazione nativa
         */
        thisForm.attr("novalidate", true);

        /* Apertura box SEO
        ------------------------------------------------------------------------------------------ */
        $('.seo_data_title').on('click', function() {
            $(this).toggleClass('active');
            $('.seo_data_box').toggle(300);
        });
        /* Controllo e autocompletamento URL (solo se la voce non è già su DB)
        ------------------------------------------------------------------------------------------ */
        thisForm.find('[data-source_url]').each(function() {

            let inputURL = $(this);
            //let sourceInput = thisForm.find('[name="'+inputURL.attr("data-source_url")+'"]').split(",");

            let selectors = inputURL.attr("data-source_url").split(",");

            let strSelector = [];
            selectors.forEach(item => {
                strSelector.push('[name="' + item + '"]');
            });

            let sourceInput = thisForm.find(strSelector.join(','));

            // Clone titolo (nell'input url), pulizia e verifica al cambiamento degli input
            sourceInput.on('blur', function() {

                // costruisco valore dalla concatenazione degli input interessati
                let val = [];
                sourceInput.each(function() {
                    if ($(this).val()) {
                        val.push($(this).val());
                    }
                });

                // continuo se ho tutti i valori interessati
                if (val.length != sourceInput.length) {
                    return;
                }


                // Se sono in inserimento (cioè non ho id)
                // o se sono in update ma il campo non ha ancora un valore.
                // in ogni caso non deve essere bloccato in seguito ad un intervento
                // manuale (vedi sotto, evento blur)
                if (
                    (!inputURL.data("id") ||
                        (inputURL.data("id") && !inputURL.val())
                    ) && inputURL.data("locked") !== true
                ) {
                    let max = inputURL[0].hasAttribute('maxlength') ? parseInt(inputURL[0].getAttribute('maxlength'),10) : 191;
                    let cleanURL = clearURL(val).substring(0,max).trim('-');
                    inputURL.val(cleanURL);

                    checkURL(inputURL); // Chiamata per verifica url
                }

            });




            // Pulizia e verifica url, su inserimento nell'"input url" stesso
            inputURL.on('blur', function() {
                let max = inputURL[0].hasAttribute('maxlength') ? parseInt(inputURL[0].getAttribute('maxlength'),10) : 191;
                let cleanURL = clearURL($(this).val()).substring(0,max).trim('-'); // Pulizia
                inputURL.val(cleanURL); // Reinserimento nello stesso input

                // blocco modifiche successive
                inputURL.data("locked", true);

                checkURL(inputURL); // Chiamata per verifica url
            });

        });


        /* Controllo TITLE
        ------------------------------------------------------------------------------------------ */

        thisForm.find('[data-source_title]').each(function() {

            let inputTitle = $(this);
            let sourceInput = thisForm.find('[name="' + inputTitle.attr("data-source_title") + '"]');
            sourceInput.on('blur', function() {

                if (
                    (!inputTitle.data("id") ||
                        (inputTitle.data("id") && !inputTitle.val())
                    )
                ) {
                    let max = inputTitle[0].hasAttribute('maxlength') ? parseInt(inputTitle[0].getAttribute('maxlength'),10) : 191;
                    let cleanTitle = clearTitle(sourceInput.val()).substring(0,max).trim();
                    inputTitle.val(cleanTitle);
                }

            });

            // Pulizia e verifica url, su inserimento nell'"input url" stesso
            inputTitle.on('blur', function() {
                let max = inputTitle[0].hasAttribute('maxlength') ? parseInt(inputTitle[0].getAttribute('maxlength'),10) : 191;
                let cleanTitle = clearTitle($(this).val()).substring(0,max).trim(); // Pulizia
                inputTitle.val(cleanTitle); // Reinserimento nello stesso input
            });

        });


        /* Date pubblicazione / De-pubblicazione
        ------------------------------------------------------------------------------------------ */
        $('.radio_publication_status').on('click', function() {
            if ($('#public_after').is(':checked') && !$('#box_publication_date_start').is(':visible')) { $('#box_publication_date_start').slideDown(300); } else if (!$('#public_after').is(':checked') && $('#box_publication_date_start').is(':visible')) { $('#box_publication_date_start').slideUp(300); }
        });
        $('#depublication_status').on('click', function() {
            if ($('#depublication_status').is(':checked') && !$('#box_publication_date_end').is(':visible')) { $('#box_publication_date_end').slideDown(300); } else { $('#box_publication_date_end').slideUp(300); }
        });


        /* rimozione classe errore
        (se un campo è stato precedentemente contrassegnato)
        ------------------------------------------------------------------------------------------ */
        thisForm.on('blur', '.uk-form-danger', function(evt) {
            $(this).removeClass("uk-form-danger");
            $(this).siblings("label").removeClass("uk-form-danger");
        });


        /* Creazione FormData
        ------------------------------------------------------------------------------------------ */
        /**
         * Creazione e riempimento del FormData
         * i form con inizio "tmp_" vengono rimpiazzati
         *
         * @param formSerialize 
         * @return null
         */
        function formSerialize() {

            /* Aggiorno gli elementi CKeditor, in modo che aggiorni i textare associati e il form riceva i dati
            ------------------------------------------------------------------------------------------ */
            if (typeof(CKEDITOR) != 'undefined' && CKEDITOR.instances) {
                for (i in CKEDITOR.instances) {
                    CKEDITOR.instances[i].updateElement();
                }
            }

            // disabilito i campi file per evitare upload (sono già stati gestiti da livewire)
            $('.input-file').prop('disabled', true);

            // Inizializzo FormData
            formData = new FormData(thisForm[0]);


            /* serializzo filtri sull'url 
            ------------------------------------------------------------------------------------------ */
            if ($(".page_url").length) {
                $.each($(".page_url").first().data("filters"), function(a, b) {
                    formData.append("url_filters[" + a + "]", b);
                });
            }


            /* Apertura layer caricamento dati
            ------------------------------------------------------------------------------------------ */
            checkForm().then(function(checkResult, reject) {

                if (checkResult !== true) {
                    return UserDialog.error(checkResult);
                }

                LoaderManager.start();

                if (checkResult) {

                    $.ajax({
                        url: options.action_url,
                        data: formData,
                        type: 'post',
                        dataType: 'json',
                        processData: false,
                        contentType: false,
                        beforeSend: function() {
                            LoaderManager.progressBar();
                        },
                        xhr: function() {
                            var xhr = new window.XMLHttpRequest();
                            //Upload progress
                            xhr.upload.addEventListener("progress", function(evt) {
                                if (evt.lengthComputable) {
                                    percentComplete = Math.ceil((evt.loaded / evt.total) * 100);

                                    // Azione durante l'upload
                                    LoaderManager.progressBarUpdate(parseInt(percentComplete));

                                }
                            }, false);
                            return xhr;
                        },
                        // finisco qui anche per errori di validazione
                        error: function(a, b) {

                            var response = a.responseJSON;

                            if (response && response.errors) {

                                var msg = [];

                                // raccolgo messaggi di errore, evidenzio i campi e costruisco
                                // messaggio unico per modal
                                $.each(response.errors, function(i) {

                                    var $f = thisForm.find('[name="' + i + '"]');
                                    $f.addClass("uk-form-danger");
                                    $f.siblings("label").addClass("uk-form-danger");
                                    // concateno messagio specifico del campo
                                    msg.push(response.errors[i].join(""));
                                });
                                var $f = thisForm.find(".uk-form-danger").first();
                                if ($f.length) { $("html").animate({ scrollTop: $f.offset().top - 40 }, 200); }

                                UserDialog.error('<strong>Attenzione</strong><br> ' + msg.join("<br>"), 0);

                            } else {
                                UserDialog.error("Si è verificato un errore.", 3000);
                            }

                            return LoaderManager.stop();

                        }
                    }).done(function(result) {

                        LoaderManager.progressBarClear();

                        UserDialog.success(result.msg);

                        let redirect = null;

                        // altrimenti recupero l'impostazione della funzione
                        if (options.redirect_url) {
                            redirect = options.redirect_url;
                        }

                        // responso lato server ha la precedenza
                        if (result.redirect.length) {
                            redirect = result.redirect;
                        }

                        // se hi redirect lo eseguo, altrimenti chiudo semplicemente il popup di caricamento
                        setTimeout(function() {
                            if (redirect) {
                                window.location = redirect;
                            } else {
                                LoaderManager.stop();
                            }
                        }, 300);

                    });

                }

                if (typeof reject !== 'undefined') { console.log(reject); }
            });

        }


        /**
         * Verifica dei campi form, se riempiti si prosegue
         *
         * @param checkForm 
         * @return boolean
         */
        function checkForm() {

            let validationResult = new Promise(function(resolve, reject) {
                
                resolve(true);

                /* setTimeout(function() { // Timeout per dare il tempo di fare la verifica dell'url
                    let toReturn = true;

                    resolve(toReturn);

                    let msg = '<h2 class="title_almost"><span class="pre-icon entypo-icon-emoji-neutral"></span>Verifica questi dati</h2>';

                    // Campi testuali richiesti
                    thisForm.find('input, textarea, select').filter('[required]').each(function() {
                        if (!$(this).val().trim()) {
                            toReturn = false;
                            required_text = $(this).data('required_text') ? $(this).data('required_text') : $(this).prev("label").text();
                            msg += '&bull; <strong>' + required_text + '</strong> è richiesto.<br>';
                        }
                    });
                    // Campi URL
                    thisForm.find('input[id^="page_url_"]').each(function() {
                        if ($(this).val() && $(this).data('url_ok') == false) {
                            toReturn = false;
                            msg += '&bull; <strong>' + $(this).data('required_text') + '</strong> non è valido.<br>';
                        }
                    });

                    // Se non valido restituisco il messaggio di errore
                    if (!toReturn) {
                        toReturn = msg;
                    }

                    resolve(toReturn);

                }, 200); */
            });
            return validationResult;
        }


        /**
         * Chiamata per verifica url
         *
         * @param object inputUrl oggetto origine, campo url
         * @return azione nel DOM
         * @todo recuperare lang clean
         */
        function checkURL(inputURL) {

            let lang = inputURL.data('lang');
            let currentId = inputURL.data("id");
            let cleanURL = inputURL.val();

            $.ajax({
                method: 'GET',
                url: options.check_url,
                dataType: 'json',
                data: {
                    id: currentId,
                    function_code: options.function_code,
                    lang: lang,
                    url: cleanURL,
                    filters: inputURL.data("filters")
                },
                success: function(result) {

                    if (result.error) {
                        $('#page_url_' + lang + '_result').removeClass('success').addClass("error").find("span.message").text(result.error);
                        $('#page_url_' + lang).data('url_ok', false);
                    } else {
                        $('#page_url_' + lang + '_result').addClass('success').removeClass("error").find("span.message").text(result.msg);
                        $('#page_url_' + lang).data('url_ok', true);
                    }
                }
            });
        }


        /**
         * Pulizia url
         *
         * @param clearURL url string [mandatory]
         * @return string
         */
        function clearURL(urlToClear) {

            urlToClear = $.trim(urlToClear);
            urlToClear = urlToClear.replace(/[ÀÁÂÃÄÅ]/g, "A");
            urlToClear = urlToClear.replace(/[àáâãäå]/g, "a");
            urlToClear = urlToClear.replace(/[Æ]/g, "AE");
            urlToClear = urlToClear.replace(/[æ]/g, "ae");
            urlToClear = urlToClear.replace(/[þ]/g, "B");
            urlToClear = urlToClear.replace(/[Þ]/g, "b");
            urlToClear = urlToClear.replace(/[ÇČ]/g, "c");
            urlToClear = urlToClear.replace(/[çč]/g, "c");
            urlToClear = urlToClear.replace(/[Ð]/g, "D");
            urlToClear = urlToClear.replace(/[ð]/g, "d");
            urlToClear = urlToClear.replace(/[ÈÉÊËĚ]/g, "E");
            urlToClear = urlToClear.replace(/[èéêëě]/g, "e");
            urlToClear = urlToClear.replace(/[ÌÍÎÏ]/g, "I");
            urlToClear = urlToClear.replace(/[ìíîï]/g, "i");
            urlToClear = urlToClear.replace(/[Ñ]/g, "N");
            urlToClear = urlToClear.replace(/[ñ]/g, "n");
            urlToClear = urlToClear.replace(/[ÒÓÔÕÖØ]/g, "O");
            urlToClear = urlToClear.replace(/[òóôõöø]/g, "o");
            urlToClear = urlToClear.replace(/[Œ]/g, "OE");
            urlToClear = urlToClear.replace(/[œ]/g, "oe");
            urlToClear = urlToClear.replace(/[Ř]/g, "R");
            urlToClear = urlToClear.replace(/[ř]/g, "r");
            urlToClear = urlToClear.replace(/[Š]/g, "S");
            urlToClear = urlToClear.replace(/[šß]/g, "s");
            urlToClear = urlToClear.replace(/[ÙÚÛÜ]/g, "U");
            urlToClear = urlToClear.replace(/[ùúûü]/g, "u");
            urlToClear = urlToClear.replace(/[ŸÝ]/g, "y");
            urlToClear = urlToClear.replace(/[ÿý]/g, "y");
            urlToClear = urlToClear.replace(/[Ž]/g, "Z");
            urlToClear = urlToClear.replace(/[ž]/g, "z");
            urlToClear = urlToClear.replace(/[^a-zA-Z0-9]/g, '-'); // Solo lettere e numeri gli altri -> "-"
            urlToClear = urlToClear.replace(/[-]{2,}/g, '-'); // 2 o più -> "-"
            urlToClear = urlToClear.replace(/[-]$/g, ''); // Se finisce con "-" -> ""
            urlToClear = urlToClear.toLowerCase();

            return urlToClear;
        }

        /**
         * Pulizzia titolo
         *
         * @param clearTitle url string [mandatory]
         * @return string
         */
        function clearTitle(titleToClear) {

            titleToClear = $.trim(titleToClear);
            titleToClear = titleToClear.replace(/"/g, '″');

            return titleToClear;
        }


        /* METODI PUBBLICI
        ------------------------------------------------------------------------------------------ 
        ------------------------------------------------------------------------------------------ */
        this.initialize = function() { return this; };

        return this.initialize();
    }

    // Opzioni di default
    $.fn.SaveForm.options = {
        'action_url': '',
        'redirect_url': '',
        'check_url': '',
        'check_url_item': '',
        'page_id': 0
    }


})(jQuery, window, document);


/**
 * Crea tab per campi in lingua
 */
inputCreateLangTabs = function inputCreateLangTabs(selector) {
    $(selector).not(".inited").each(function(i) {

        var sectionIndex = i;
        var $_section = $(this);
        var $_lgBoxes = $_section.find("> li");
        var defaultLg = $_section.data("lg_default");

        // Creo tabber
        var $_tabber = $('<ul class="uk-tab" data-uk-switcher="animation: uk-animation-fade">');

        var langs = JSON.parse(GLOBALS.langs);

        $($_lgBoxes).each(function(i) {
            var $tab = $(this);
            var htmlId = "tab-" + sectionIndex + "-" + $tab.data("lg-suff");
            var title = langs[$tab.data("lg-id")]["title"];
            $tab.attr("id", htmlId);
            $('<li><a href="#' + htmlId + '">' + title + '</a></li>').appendTo($_tabber);
        });
        $_tabber.insertBefore($_section);

        //$_section.tabs({active: 0});

        $_section.addClass("inited");

    });

    return inputCreateLangTabs;
};



/**
 * Tooltip per info su label dei controlli form
 */
inputCreateTooltips = function(selector) {
    $(selector).not(".inited").each(function(i) {
        var $this = $(this);
        var txt = $(this).text();
        $this.attr('data-uk-tooltip', 'title: ' + txt + '; pos: bottom');
        $this.html('<span data-uk-icon="info"></span>');
    });
}


treeSelector2 = function(selector) {

    var $tree = $(selector);
    var $check_selection = $tree.find(".check_selection");
    var $check_selection_li = $tree.find("li");
    var $check_selection_li_cat = $tree.find("li.cat");

    var update = function($elem) {

        if ($elem) {
            var checked = $elem.prop("checked");

            // aggiorno tutti i figli con la proprieta ereditata
            $elem.siblings(".itemTreeLevel").find(".check_selection:not(:disabled)").prop("checked", checked);

            // rimuovo il check dal padre
            if (checked == false) { $elem.parentsUntil("item.cat").children(".check_selection_cat").prop("checked", false); }
        }

        // per ogni lista, controllo quante voci sono selezionate sul totale e aggiorno di conseguenza
        $check_selection.each(function() {
            // conteggio elementi selezionati
            var childCount = $(this).siblings(".itemTreeLevel").find(".check_selection_item:not(:disabled)").length;
            var checkedChildCount = $(this).siblings(".itemTreeLevel").find(".check_selection_item:not(:disabled):checked").length;
            $(this).next("label").children("small").text(checkedChildCount + '/' + childCount);

            // rimuovo classi precedenti
            $(this).removeClass("full").removeClass("partial");
            $(this).next("label").children("small").text(checkedChildCount + '/' + childCount);

            if (checkedChildCount > 0 && checkedChildCount == childCount) {
                $(this).prop("checked", true);
                $(this).addClass("full");
            } else if (checkedChildCount > 0 && checkedChildCount < childCount) {
                $(this).addClass("partial");
            } else if (childCount == 0 && $(this).is(":checked")) {
                $(this).addClass("full");
            }

        });

    }

    /**
     * filtro interno alla lista
     */
    var $textbox = $tree.find('[type="search"]');
    $textbox.bind('change keyup', function() {

        var search = $.trim($(this).val());
        var regex = new RegExp(search, "gi");

        // espando tutto l'albero
        $tree.find(".sublevel_toggler").prop("checked", true);

        if (!search) {
            $check_selection_li.show();
            $check_selection_li.removeClass("filtered_out");
            return;
        }

        // nascondo tutte le opzioni...            
        $check_selection_li.show();


        // .. e mostro quelle corrispondenti alla ricerca
        $.each($check_selection_li, function(i) {


            var text = $(this).children(".check_selection_label").text();
            if (
                text.match(regex) !== null ||
                text.toLowerCase().search(search.toLowerCase()) != -1
            ) {
                $(this).removeClass("filtered_out").show();
            } else {
                $(this).addClass("filtered_out")
            }
        });

        // nascondo gli elementi che non hanno figli
        $check_selection_li.filter(".filtered_out").each(function() {
            var children = $(this).find("li");
            if (!children.length) { $(this).hide(); }
        });

        $check_selection_li.filter(":not(.filtered_out)").each(function() {
            if ($(this).find("li").length) { $(this).find(".itemTreeLevel li").show(); }
        });


        // nascondo i genitori nell'albero
        /*$.each($check_selection_li_cat, function(i) {
            var childrenCount = $(this).find(".item.item").length;
            var childrenCountActive = $(this).find(".item.item:visible").length;
            if (childrenCount > childrenCountActive) {
                $(this).addClass("filtered_out");
            } else {
                $(this).removeClass("filtered_out");
            }
        });*/

    });


    // situazione iniziale, elementi disabilitati
    $check_selection.each(function() {

        // se contiene solo elementi disabilitati 
        var childCount = $(this).siblings(".itemTreeLevel").find(".check_selection_item").length;
        var disabledChildCount = $(this).siblings(".itemTreeLevel").find(".check_selection_item:disabled").length;
        if (disabledChildCount && disabledChildCount == childCount) {
            $(this).next("label").children("small").text(0);
            $(this).prop("disabled", true);
        }
    });


    // aggiornamento iniziale albero
    update();

    // aggiornamento in risposta a selezioni utente
    $check_selection.on("click", function() {
        update($(this));
    });

    // apertua livelli albeo
    $tree.on("click", ".sublevel_toggler_label", function() {
        if ($(this).prev("input").prop("checked")) {
            $(this).prev("input").prop("checked", false);
        } else {
            $(this).prev("input").prop("checked", true);
        }
    });

}


/*
 * Alert per abbandono voci senza salvataggio.
 */
changesAlert = function(selector) {

    var changes = false;

    // evento di cambiamento per campi generici
    var $_changeable = $(selector).find("input,textarea,select");
    if ($_changeable.length) {

        // selettore per elementi che mi portano fuori dalla pagina
        var clickable = '#sidebar-inner ul a, #page-content-titlebar ul a, .input-navbar a.list';
        $(document).on("click", clickable, function(e) {

            e.stopImmediatePropagation();
            const item = e.target;
            const is_wildcard = item.classList.contains('wildcard');

            if (changes && !is_wildcard) {

                e.preventDefault();

                var href = $(this).attr("href");

                UserDialog.confirm('Sei sicuro di voler abbandonare questa pagina senza salvare?', 'Modifiche non salvate', function() {
                    window.location = href;
                });
            }

        });

        // al cambiamento dei valori dei campi lancio evento di segnalazione
        $_changeable.on("change", function(e) {
            changes = true;
        });
    }


}


/**
 * Gestione barra di caricamento per input file
 */
document.addEventListener('livewire:load',() => {

    $(document).on('livewire-upload-progress', '.input-file', function(event) {
        let input = event.target;
        let selector = '#' + input.dataset.progress;
        let progressBar = document.querySelector(selector),
            progressBarLoaded = progressBar.querySelector('.progress_bar__loaded');

        progressBar.style.visibility = "visible";
        progressBarLoaded.style.width = `${event.detail.progress}%`;

    });
});


